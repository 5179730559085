import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const subscriptionSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'subscriptions',
    label: 'subscriptions',
    routerLink: '/subscriptions',
    permissions: ['VIEW_SUBSCRIPTION'],
  },
  {
    id: 'transactions',
    label: 'transactions',
    routerLink: '/transactions',
    permissions: ['VIEW_BILLING_TRANSACTION'],
  },
  {
    id: 'plan',
    label: 'plan',
    routerLink: '/plan',
    permissions: ['VIEW_PLAN'],
  },
  {
    id: 'voucher',
    label: 'voucher',
    routerLink: '/voucher',
    permissions: ['VIEW_VOUCHER'],
  },
];
