import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'update-referral',
    loadChildren: () =>
      import('./modules/update-referrals/update-referrals.module').then(
        ({ UpdateReferralsModule }) => UpdateReferralsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
