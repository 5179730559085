import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'chat-support',
    loadChildren: () =>
      import('./chat-support/chat-support.module').then(
        ({ ChatSupportModule }) => ChatSupportModule
      ),
  },
  {
    path: 'email-support',
    loadChildren: () =>
      import('./support-emails/support-emails.module').then(
        ({ SupportEmailsModule }) => SupportEmailsModule
      ),
  },
  {
    path: 'suggestions',
    loadChildren: () =>
      import('./suggestions/suggestions.module').then(
        ({ SuggestionsModule }) => SuggestionsModule
      ),
  },
  {
    path: 'lost-items-requests',
    loadChildren: () =>
      import('./lost-items-requests/lost-items-requests.module').then(
        ({ LostItemsRequestsModule }) => LostItemsRequestsModule
      ),
  },
  {
    path: 'vehicle-malfunction',
    loadChildren: () =>
      import('./vehicle-malfunction/vehicle-malfunction.module').then(
        ({ VehicleMalfunctionModule }) => VehicleMalfunctionModule
      ),
  },
  {
    path: 'route-requests',
    loadChildren: () =>
      import('./route-requests/route-requests.module').then(
        ({ RouteRequestsModule }) => RouteRequestsModule
      ),
  },
  {
    path: 'complaints',
    loadChildren: () =>
      import('./complaint/complaint.module').then(
        ({ ComplaintModule }) => ComplaintModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagementRoutingModule {}
