import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const settingsSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'setting',
    label: 'setting',
    routerLink: '/update-referral',
    permissions: ['VIEW_REFERRAL_VOUCHER', 'UPDATE_REFERRAL_VOUCHER'],
  },
];
